.app-header {
  border-bottom: 1px solid rgb(106, 106, 106);
}

.app-header-item {
  color: rgb(34, 34, 34);
  font-weight: 600;
  display: inline-block;
  padding: 10px;
  text-decoration: none;
}

.app-header-item-active {
  background-color: pink;
}
